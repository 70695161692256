import { Controller } from 'stimulus';
import $ from 'jquery';
import Select2 from "select2";

export default class extends Controller {
  static targets = [
    'changeCore',
    'cloneFrom',
    'form',
    'cushionCoreMaterials',
    'dressCoverMaterials',
    'cushionCoreRequiredField',
    'material',
  ];

  static values = {
    cushionId: Number,
    seatId: Number,
  };

  initialize() {
    window.console.log('CushionForm Controller Initialized!');
  }

  connect() {
    this._toggleCore(0);
  }

  toggleCore() {
    this._toggleCore(50);
  }

  _toggleCore(speed) {
    $(this.cushionCoreRequiredFieldTargets).prop('required', this.coreChanges);
    this.toggleCoreColumns(50);

    if (this.coreChanges) {
      $(this.changeCoreColumnTargets).show(speed);
    } else {
      $(this.changeCoreColumnTargets).hide(speed);
    }
  }

  toggleCoreColumns(speed) {
    const $coreMaterials = $(this.cushionCoreMaterialsTarget)
    if (this.coreChanges) {
      $coreMaterials.find('.lot, .certification').show(speed);
    } else {
      $coreMaterials.find('.lot, .certification').hide(speed);
    }
  }

  clone() {
    $.ajax({
      method: 'GET',
      url: `/cushions/${this.cloneFromTarget.value}`,
      dataType: 'json',
      complete: (response)=> {
        const json = response.responseJSON;
        console.log(json);
        const $element = $(this.element);

        for (const attr in json) {
          const dontUpdate = ['id', 'seat_id', 'cushion_type'];
          if (dontUpdate.includes(attr)) { continue; }

          const $input = $element.find(`#cushion_${attr}`);

          if ($input) { $input.val(json[attr]); }

          if ([true, false].includes(json[attr])) {
            const $radioInput = $element.find(`#cushion_${attr}_${json[attr]}`);

            if ($radioInput) {
              $radioInput.prop('checked', true);
            }
          }
        }

        this.updateMaterials(json.materials);

        this.cloneFromTarget.value = '';
      },
    });
  }

  updateMaterials(materialsData) {
    this.removeMaterials();

    $(materialsData).each((_i, materialData) => {
      const $materials = this.materialsElementFor(materialData.material_type);

      this.cloneMaterial($materials, materialData).then(($newMaterial) => {
        const $name = $newMaterial.find('.name');
        const text = this.materialOptionLabel(materialData);

        $name.find('option').each((_i, option) => {
          const $option = $(option);
          if ($option.text() == text) {
            $option.prop('selected', true);
          }
        });

        const event = new Event("change", {bubbles: true});
        $name[0].dispatchEvent(event);

        this.toggleCore();
      });
    });
  }

  materialOptionLabel(materialData) {
    const attributes = [
      materialData.manufacturer,
      materialData.part_number,
      materialData.lot,
      materialData.certification,
    ].filter( (value) => value);

    return `${materialData.name} (${attributes.join(", ")})`
  }

  materialsElementFor(materialType) {
    switch (materialType){
      case 'Cushion Core':
        return $(this.cushionCoreMaterialsTarget);
      case 'Dress Cover':
        return $(this.dressCoverMaterialsTarget);
    }
  }

  cloneMaterial($materials, materialData) {
    return new Promise((resolve) => {
      $.ajax({
        method: 'GET',
        url: '/materials/new',
        data: {
          material_type: materialData.material_type,
          has_materials_type: 'Cushion',
          seat_id: this.seatId,
        },
        dataType: 'html',
        complete: (response)=> {
          const materialElement = $(response.responseText);

          $materials.find('table [data-materials-target="list"]').append(materialElement);
          materialElement.find('input[type=text]').first().focus();
          resolve(materialElement);
        },
      });
    });
  }

  removeMaterial($material) {
    const materialId = $material.data('id');

    if (materialId) {
      $.ajax({
        method: 'DELETE',
        url: `/materials/${materialId}`,
        dataType: 'script',
      });
    }

    $material.detach()
  }

  removeMaterials() {
    $(this.materialTargets).each((_i, material)=> {
      this.removeMaterial($(material));
    });
  }

  get coreChanges() {
    return this.changeCoreTarget.checked;
  }

  get cushionId() {
    return this.cushionIdValue;
  }

  get seatId() {
    return this.seatIdValue;
  }
}
