import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['otherAircraftOperationWrapper', 'hasOtherAircraftOperation'];

  initialize() {
    window.console.log('AircraftForm Controller Initialized!');
  }

  connect() {
    this.render();
  }

  render() {
    if ($(this.hasOtherAircraftOperationTarget).prop('checked')) {
      $(this.otherAircraftOperationWrapperTarget).show();
    } else {
      $(this.otherAircraftOperationWrapperTarget).hide()
                                                 .find('input[type=text]')
                                                 .val('');
    }
  }
}
