export function getRadioGroupValue(radioButtons) {
  for (let i = 0; i < radioButtons.length; i++) {
    const radio = radioButtons[i];

    if (radio.checked) {
      return radio.value;
    }
  }
}

export function setRadioGroupValue(radioButtons, value) {
  for (let i = 0; i < radioButtons.length; i++) {
    const radio = radioButtons[i];

    if (radio.value === value) {
      radio.checked = true;
      return;
    }
  }
}

export function scrollToBottom() {
  window.scrollTo({top: document.body.scrollHeight + 100, behavior: 'smooth'});
}
