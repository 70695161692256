import { Controller } from 'stimulus';

export default class extends Controller {
  // This controlers is used on both samples/forms/certification and samples/forms/test.
  static targets = ['testType', 'unknownTestTypeInfo', 'unknownTestTypeField'];

  static values = {
    sampleId: Number,
    jobType: String,
  }

  initialize() {
    window.console.log('SampleForm Controller Initialized!');
  }

  connect() {
    if (!this.sampleIsNew) {
      $('tr.material [type=text]').first().focus();
    }

    this._updateTestType(0);
  }

  updateTestType() {
    this._updateTestType(100);
  }

  _updateTestType(speed) {
    if (this.hasTestTypeTarget) {
      switch (this.testType) {
        case 'Unknown':
          $(this.unknownTestTypeInfoTarget).show(speed);
          this.showUnknownTestTypeField(speed);
          break;
        case 'Other (list below)':
          $(this.unknownTestTypeInfoTarget).hide(speed);
          this.showUnknownTestTypeField(speed);
          break;
        default:
          $(this.unknownTestTypeInfoTarget).hide(speed);
          $(this.unknownTestTypeFieldTarget).hide(speed);
      }
    }
  }

  showUnknownTestTypeField(speed) {
    $(this.unknownTestTypeFieldTarget).show(speed)
                                      .find('input[type=text]')
                                      .focus();
  }

  get sampleIsNew() {
    return !this.hasSampleIdValue;
  }

  get testType() {
    return this.testTypeTarget.value;
  }
}
