import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "copyable",
  ];

  static values = {
    attributeBase: String,
  }

  initialize() {
    window.console.log("CopyInputValues Controller Initialized!");
  }

  copy() {
    this.copyableTargets.forEach( (copyable) => {
      const attribute = copyable.dataset.attribute;
      const target = document.getElementById(`${this.attributeBase}_${attribute}`);
      target.value = copyable.value;
    });
  }

  get attributeBase() {
    return this.attributeBaseValue;
  }
}
