import { Controller } from "stimulus";
import $ from 'jquery';
import Select2 from "select2";

export default class extends Controller {
  static targets = [
    "name",
    "manufacturer",
    "partNumber",
    "lot",
    "certification",
    "simulatedTrue",
    "simulatedFalse",
  ];

  initialize() {
    window.console.log("Material Controller Initialized!");
  }

  connect() {
    this.initMaterialLookup();
    this.initFillNameFromSearch();
  }

  disconnect() {
    this.uninitMaterialLookup();
  }

  initMaterialLookup() {
    $(this.nameTarget).on("select2:select", function() {
      const event = new Event("change", {bubbles: true});
      this.dispatchEvent(event);
    });

    $(this.nameTarget).select2({
      tags: true,
      templateSelection: function(state) {
        const $option = $(state.element);
        const data = $option.data();

        if (data.materialData) {
          return data.materialData.name;
        } else {
          return state.id;
        }
      },
      language: {
        noResults: function() {
          return  ""
        }
      },
    });
  }

  uninitMaterialLookup() {
    $(this.nameTarget).select2('destroy');
  }

  initFillNameFromSearch() {
    // Select2 does not add a selection if the user just clicks off or, more likely,
    // clicks the next field. This captures the value of the Search, adds it to Select2,
    // and triggers the "change" event on the Select2 field.
    $(this.nameTarget).on("select2:closing", function(event) {
      const searchValue = $(".select2-search__field").val();
      const fieldValue = $(this).val();

      if (searchValue != "") {
        $(this).val(searchValue);
        $(this).trigger("change");
      }
    });
  }

  clone(event) {
    const $option = $(event.target).find("option:selected");
    const data = $option.data();

    if ("materialData" in data) {
      // If this is an existing Material.
      this.transcribe(data.materialData);
    }
  }

  transcribe(data) {
    if (this.hasManufacturerTarget) {
      $(this.manufacturerTarget).val(data.manufacturer);
    }

    if (this.hasPartNumberTarget) {
      $(this.partNumberTarget).val(data.part_number);
    }

    if (this.hasLotTarget) {
      $(this.lotTarget).val(data.lot);
    }

    if (this.hasCertificationTarget) {
      $(this.certificationTarget).val(data.certification);
    }

    if (this.hasSimulatedTrueTarget) {
      if (data.simulated) {
        $(this.simulatedTrueTarget).prop("checked", true);
      } else {
        $(this.simulatedFalseTarget).prop("checked", true);
      }
    }
  }
}
