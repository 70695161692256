import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "list",
  ];

  static values = {
    materialType: String,
    hasMaterialsId: Number,
    hasMaterialsType: String,
    seatId: Number,
    jobId: Number,
  }

  initialize() {
    window.console.log("Materials Controller Intialized!");
  }

  addMaterial(event) {
    event.preventDefault();
    this._addMaterial();
  }

  _addMaterial() {
    return new Promise((resolve) => {
      $.ajax({
        method: "GET",
        url: "/materials/new",
        data: {
          material_type: this.materialTypeValue,
          has_materials_id: this.hasMaterialsIdValue,
          has_materials_type: this.hasMaterialsTypeValue,
          seat_id: this.seatIdValue,
          job_id: this.jobIdValue,
        },
        dataType: "html",
        complete: (response)=> {
          const materialElement = $(response.responseText);
          $(this.listTarget).append(materialElement);
          materialElement.find("select").first().focus();

          const event = new Event("materialAdded");
          document.dispatchEvent(event);
        },
      });
    });
  }

  removeMaterial(event) {
    event.preventDefault();
    const $target = $(event.target);
    const $material = $target.closest(".material");

    this._removeMaterial($material);
  }

  _removeMaterial($material) {
    const materialId = $material.data("id");

    if (materialId) {
      $.ajax({
        method: "DELETE",
        url: `/materials/${materialId}`,
        dataType: "script",
      });
    }

    $material.detach()
  }

  removeMaterials() {
    $(this.element).find(".material").each((_i, material)=> {
      this._removeMaterial($(material));
    });
  }
}
