import { DirectUpload } from 'activestorage';

export class DocumentUploader {
  constructor(file, documentsController) {
    this.file = file;
    this.documentsController = documentsController;
    this.$progressBar = $(documentsController.progressBar);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      "progress",
      event => this.directUploadDidProgress(event)
    );
  }

  directUploadDidProgress(event) {
    const completeness = (event.loaded / event.total) * 100;
    this.$progressBar.find('.inner').css('width', `${completeness}%`);
  }

  uploadAttachment(){
    this.$progressBar.find('.filename').text(this.file.name);
    $(this.documentsController.listTarget).prepend(this.$progressBar);

    const upload = new DirectUpload(
      this.file,
      '/rails/active_storage/direct_uploads',
      this
    );

    return new Promise( (resolve) => {
      upload.create((error, attachment) => {
        if (error) {
          const message = '<span class="text-red-700">Document failed to upload. Please try again.</span>';
          this.setProgressMessage(message);
        } else {
          this.setProgressMessage('Finalizing.......');

          this.postAttachment(attachment).then( () => {
            resolve(this);
          });
        }
      });
    });
  }

  postAttachment(attachment) {
    return new Promise( (resolve) => {
      $.ajax({
        method: 'POST',
        url: '/documents',
        data: {
          id: this.documentsController.data.get('hasDocumentsId'),
          type: this.documentsController.data.get('hasDocumentsType'),
          document: {
            attachment: attachment.signed_id
          }
        },
        complete: (response) => {
          const $html = $(response.responseText);
          $(this.$progressBar).replaceWith($html);
          resolve();
        }
      });
    });
  }

  setProgressMessage(message) {
    this.$progressBar.find('.outer').replaceWith(`<div>${message}</div>`);
  }
}
