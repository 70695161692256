import { Controller } from 'stimulus';
import { DocumentUploader } from '../utilities/document_uploader.js'

export default class extends Controller {
  static targets = [
    'filesInput',
    'list',
    'submitButton',
    'progressBarTemplate'
  ];

  initialize() {
    window.console.log('Documents Controller Initialized!');
  }

  connect() {
    $(this.element).find('input[type=text]').first().select();
  }

  add(event) {
    this.uploadAttachments();
  }

  uploadAttachments() {
    this.disableUploads();
    this.fileCount = this.files.length;

    $(this.files).each((_i, file) => {
      this.uploadAttachment(file);
    });
  }

  uploadAttachment(file) {
    const uploader = new DocumentUploader(file, this);

    uploader.uploadAttachment().then( (uploader) => {
      this.fileCount -= 1;

      if (this.fileCount == 0) {
        this.enableUploads();
      }
    });
  }

  disableUploads() {
    $(this.filesInputTarget).prop('disabled', true);

    if (this.hasSubmitButtonTarget) {
      $(this.submitButtonTarget).prop('disabled', true);
    }
  }

  enableUploads() {
    $(this.filesInputTarget).prop('disabled', false).val(null);

    if (this.hasSubmitButtonTarget) {
      $(this.submitButtonTarget).prop('disabled', false);
    }
  }

  get files() {
    return this.filesInputTarget.files;
  }

  get progressBar() {
    return this.progressBarTemplateTarget.innerHTML;
  }
}
