import { Controller } from 'stimulus';
import {
  getRadioGroupValue,
  setRadioGroupValue,
  scrollToBottom } from '../utilities/utilities';

export default class extends Controller {
  static targets = [
    'option',
    'repairSeat',
    'repairSeatFields',
    'oilBurn',
    'dynamicallyCertified',
    'repairType',
    'eightOneTenApprovalTrue',
    'eightOneTenApprovalFalse',
    'derLetter',
    'samplesBuiltBy',
    'willProvideMaterialSamplesFields',
    'sampleBuilderInfo',
    'sampleBuilderField',
    'dynamicallyCertifiedFields',
    'certificationRequired',
  ];

  static values = {
    jobType: String
  }

  initialize() {
    window.console.log('JobForm Controller Initialized!');
  }

  connect() {
    if (this.jobType === 'certification') {
      this._updateRepairSeat(0);
      this._updateRepairType(0);
      this._updateSampleBuilder(0);
    }
  }

  updateRepairSeat() {
    this._updateRepairSeat(50);
  }

  _updateRepairSeat(speed) {
    if (this.repairSeat) {
      $(this.repairSeatFieldsTarget).show(speed, () => scrollToBottom());
      this.oilBurn = 'true';
      this._updateOilBurn(speed);
    } else {
      $(this.repairSeatFieldsTarget).hide(speed);
      this.oilBurn = '';
      this._updateOilBurn(speed);
      this.dynamicallyCertified = '';
    }
  }

  updateSampleBuilder(){
    this._updateSampleBuilder(50);
  }

  _updateSampleBuilder(speed) {
    switch (this.sampleBuilder) {
      case 'company':
        this.showWillProvideMaterialSamples(speed);
        break;
      case 'company_with_instructions':
        this.showWillProvideMaterialSamples(speed);
        break;
      case 'us':
        this.hideWillProvideMaterialSamples(speed);
        break;
      default:
        this.hideWillProvideMaterialSamples(speed);
    }

    this.showSampleBuilderInfo(speed);
  }

  showSampleBuilderInfo(speed) {
    this.sampleBuilderInfoTargets.forEach( (element) => {
      if (element.dataset.option == this.sampleBuilder) {
        $(element).show(speed, () => scrollToBottom());
      } else {
        $(element).hide(speed);
      }
    });
  }

  showWillProvideMaterialSamples(speed) {
    if (!this.hasWillProvideMaterialSamplesFieldsTarget) { return; }

    let $fields = $(this.willProvideMaterialSamplesFieldsTarget);
    $fields.find('input[type=checkbox]').prop('required', true);
    $fields.show(speed);
  }

  hideWillProvideMaterialSamples(speed) {
    if (!this.hasWillProvideMaterialSamplesFieldsTarget) { return; }

    let $fields = $(this.willProvideMaterialSamplesFieldsTarget);
    $fields.find('input[type=checkbox]').prop('required', false);
    $fields.hide(speed);
  }

  updateOilBurn() {
    this._updateOilBurn(50);
  }

  _updateOilBurn(speed) {
    if (this.oilBurn === 'true') {
      $(this.sampleBuilderFieldTarget).show(speed, scrollToBottom());
    } else if (this.oilBurn === 'false') {
      $(this.sampleBuilderFieldTarget).hide(speed);
    } else {
      $(this.sampleBuilderFieldTarget).hide(speed);
    }
  }

  updateRepairType() {
    this._updateRepairType(50);
  }

  _updateRepairType(speed) {
    const $true = $(this.eightOneTenApprovalTrueTarget);
    const $false = $(this.eightOneTenApprovalFalseTarget);

    if (this.eightOneTenApprovalDisabled) {
      $false.prop('checked', true).prop('disabled', true).addClass('disabled');
      $true.prop('checked', false).prop('disabled', true).addClass('disabled');
    } else {
      $false.prop('disabled', false).removeClass('disabled');
      $true.prop('disabled', false).removeClass('disabled');
    }

    this.updateEightOneTenApproval(speed);
  }

  updateEightOneTenApproval(speed) {
    this._updateEightOneTenApproval(speed)
  }

  _updateEightOneTenApproval(speed) {
    const $targets = $(this.derLetterTargets);
    const $derLetterField = $targets.first().closest('.field');

    if (this.eightOneTenApproval) {
      $derLetterField.hide(speed);
      $targets.prop('checked', false).prop('required', false);
    } else {
      $derLetterField.show(speed, scrollToBottom());
      if (this.jobType === 'certification') {
        $targets.prop('required', true);
      }
    }
  }

  validateName(event) {
    if (event.key === '/') {
      event.preventDefault();
      window.alert('The "/" key is not permited in Job names');
    }
  }

  get sampleBuilder() {
    let value = null;

    this.samplesBuiltByTargets.forEach( (input) => {
      if (input.checked) { value = input.value; }
    });

    return value;
  }

  get repairSeat() {
    return this.repairSeatTarget.checked;
  }

  get jobType() {
    return this.jobTypeValue;
  }

  get repairSelection() {
    let selection = null;

    this.repairTypeTargets.forEach( (option) => {
      if (option.checked) {
        selection = option;
        return;
      }
    });

    return selection;
  }

  get repairType() {
    return this.repairSelection.value;
  }

  get eightOneTenApproval() {
    return this.eightOneTenApprovalTrueTarget.checked;
  }

  get eightOneTenApprovalDisabled() {
    if (this.repairSelection === null) { return false; }

    return this.repairSelection.dataset.disableEightOneTen === 'true';
  }

  get oilBurn() {
    return getRadioGroupValue(this.oilBurnTargets);
  }

  set oilBurn(value) {
    setRadioGroupValue(this.oilBurnTargets, value);
  }

  set dynamicallyCertified(value) {
    setRadioGroupValue(this.dynamicallyCertifiedTargets, value);
  }
}
