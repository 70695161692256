import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'certifications',
    'quantity',
    'certificationDestroyer',
  ];

  initialize() {
    window.console.log('SeatForm Controller Initialized!');
  }

  connect() {
    this.showCertifications();
  }

  updateCertification() {
    this.showCertifications();
  }

  showCertifications() {
    $(this.certificationDestroyerTargets).each((i, field) => {
      const $field = $(field);
      const $row = $field.closest('tr');

      if ($row.data('id')) {
        // If this row represents real data, we want to delete it in the DB.
        $field.val('1');
        $row.hide();
        $row.find('input[type=text]').prop('required', false);
      } else {
        $row.detach();
      }
    });

    this.fetchCertifications();
  }

  fetchCertifications() {
    // Get existing and new (up to :quantity) SeatCertifications. The rows are
    // added via the Rails js view.
    const seatId = this.data.get('seatId') || 'new';

    $.ajax({
      method: 'GET',
      url: `/seats/${seatId}/seat_certifications/new?quantity=${this.quantity}`,
      dataType: 'script',
    });
  }

  get quantity() {
    return this.quantityTarget.value;
  }
}
