import { Controller } from "stimulus";
import { Popover } from "flowbite";

export default class extends Controller {
  static targets = ["trigger", "content"];

  static values = {
    placement: {type: String, default: "top"}
  }

  initialize() {
    window.console.log("Help Controller Initialized!");
  }

  connect() {
    const options = {
      placement: this.placementValue,
      triggerType: "click"
    }

    const popover = new Popover(this.contentTarget, this.triggerTarget, options);
  }

  pop(event) {
    event.preventDefault();
  }
}
