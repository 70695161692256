import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'jobNumberField', 'message'];

  initialize() {
    window.console.log('JobJumper Controller Initialized!');
    this.jobNumber = '';
    this.clearMessage();
  }

  checkAndSubmit(event) {
    event.preventDefault();

    $.ajax({
      method: 'GET',
      url: `/jobs/${this.jobNumber}/presence`,
      complete: (response) => {
        if (response.responseJSON) {
          window.location = this.action;
        } else {
          this.showMessage();
          $(this.jobNumberFieldTarget).select();
        }
      }
    });
  }

  update(event) {
    if (/\d/.test(event.key)) {
      this.clearMessage();
    }
    this.action = `/jobs/job_${this.jobNumber}`;
  }

  clearMessage() {
    this.messageTarget.style.display = 'none';
  }

  showMessage() {
    this.messageTarget.style.display = 'inline';
  }

  get jobNumber() {
    return this.jobNumberFieldTarget.value;
  }

  set jobNumber(value) {
    this.jobNumberFieldTarget.value = value
  }

  get action() {
    return this.formTarget.action;
  }

  set action(value) {
    this.formTarget.action = value;
  }
}
