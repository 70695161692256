import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'returnSamples',
    'returnSampleFields',
    'sewOnTags',
    'sewOnTagsFields',
    'tsoTags',
    'tsoTagsFields',
  ];

  static values = {
    jobId: Number,
    jobType: String,
  }

  initialize() {
    window.console.log('JobConfirm Controller Initialized!');
  }

  connect() {
    // this._updateReturnSamples(0);

    if (this.jobTypeValue == 'certification') {
      this._updateSewOnTags(0);
      this._updateTsoTags(0);
    }
  }

  updateSewOnTags() {
    this._updateSewOnTags(100);
  }

  _updateSewOnTags(speed) {
    if (!this.hasSewOnTagsFieldsTarget) { return; }

    const $fields = $(this.sewOnTagsFieldsTarget);

    if (this.sewOnTags) {
      $fields.show(speed);
      $fields.find('[type=number]').first().focus();
    } else {
      $fields.hide(speed);
    }
  }

  updateTsoTags() {
    this._updateTsoTags(100);
  }

  _updateTsoTags(speed) {
    if (!this.hasTsoTagsFieldsTarget) { return; }

    const $fields = $(this.tsoTagsFieldsTarget);

    if (this.tsoTags) {
      $fields.show(speed);
      $fields.find('[type=number]').first().focus();
    } else {
      $fields.hide(speed);
    }
  }

  // updateReturnSamples() {
  //   this._updateReturnSamples(100);
  // }
  //
  // _updateReturnSamples(speed) {
  //   const $fields = $(this.returnSampleFieldsTarget);
  //
  //   if (this.returnSamples) {
  //     $fields.find('[type=text]').prop('required', true);
  //     $fields.show(speed);
  //     $fields.find('[type=text]').first().focus();
  //   } else {
  //     $fields.find('[type=text]').prop('required', false);
  //     $fields.hide(speed);
  //   }
  // }
  //
  // get returnSamples() {
  //   return this.returnSamplesTarget.checked;
  // }

  get sewOnTags() {
    return this.sewOnTagsTarget.checked;
  }

  get tsoTags() {
    return this.tsoTagsTarget.checked;
  }
}
