import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["list", "toggler"];

  static values = {
    show: Boolean
  }

  initialize() {
    window.console.log("JobsTable Controller Initialized!");
  }

  connect() {
    this.renderList(0);
  }

  toggle() {
    this.show = !this.show;
    this.renderList(200);
  }

  renderList(speed) {
    if (this.show) {
      $(this.listTarget).show(speed);
      $(this.togglerTarget).text('-');
    } else {
      $(this.listTarget).hide(speed);
      $(this.togglerTarget).text('+');
    }
  }

  get show() {
    return this.showValue;
  }

  set show(show) {
    this.showValue = show;
  }
}
